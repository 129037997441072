<script>
import Layout from "../../layouts/main";

import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import { FormWizard, TabContent } from "vue-form-wizard";
import HotelComponent from "@/components/reservation/HotelComponent.vue";
import TourComponent from "@/components/reservation/TourComponent.vue";
import { typeEnum } from "@/components/enums.js";
import TourTable from "../../../components/reservation/TourTable";
import axios from "axios";
import EditReservation from "../../../components/reservation/EditReservation";
import HotelTable from "../../../components/reservation/HotelTable";
import FlyComponent from "../../../components/reservation/FlyComponent.vue";
import FlyTable from "../../../components/reservation/FlyTable";
import { Icon } from "@iconify/vue2";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Otel Listesi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Icon,
    Layout,
    PageHeader,
    //FormWizard,
    //TabContent,
    HotelComponent,
    TourComponent,
    HotelTable,
    TourTable,
    EditReservation,
    FlyTable,
    FlyComponent,
  },
  data() {
    return {
      isLoading: false,
      isSaved: false,
      title: "Rezervasyon Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Rezervasyonlar",
          href: "/reservation",
        },
        {
          text: "Rezervasyon Güncelleme",
          active: true,
        },
      ],
      selectedValues: [],
      tourRoutes: [],
      reservations: [],
      extras: {
        baby: 0,
        children: 0,
        adult: 0,
        first_date: "",
        last_date: "",
        total_day: 0,
      },
      editProps: {
        hotels: [],
        rooms: [],
        currencies: [],
        customers: [],
        airports: [],
      },
      editTourProps: {
        cities: [],
        vehicles: [],
        currencies: [],
        drivers: [],
        agencies: [],
      },
      flyRoutes: [],
      typeEnum,
      currentStep: 1, // Başlangıç adımı
      loadedReservation: null,
      hotelCheck: false,
      tourCheck: false,
      flyCheck: false,
      steps: [
        { icon: "mdi mdi-account-circle" },
        { icon: "mdi mdi-checkbox-marked-circle-outline" },
      ],
      stepIndexes: {
        hotelStep: null,
        tourStep: null,
        flyStep: null,
      },
    };
  },
  created() {
    this.getAllHotel();
    this.getAllCurrency();
    this.getAllCity();
    this.getAllVehicle();
    this.getAllDrivers();
    this.getAllAgency();
    this.getAllCustomer();
    this.getAllAirports();
  },
  watch: {
    // Checkbox'ların değişimini izleyerek adımları güncelle
    hotelCheck() {
      this.updateSteps();
    },
    tourCheck() {
      this.updateSteps();
    },
    flyCheck() {
      this.updateSteps();
    },
  },
  methods: {
    updateSteps() {
      this.steps = [{ icon: "mdi mdi-account-circle" }];
      this.stepIndexes = { hotelStep: null, tourStep: null, flyStep: null };

      let stepCounter = 2;

      if (this.hotelCheck) {
        this.steps.push({ icon: "mdi mdi-home-city-outline" });
        this.stepIndexes.hotelStep = stepCounter;
        stepCounter++;
      }

      if (this.tourCheck) {
        this.steps.push({ icon: "mdi mdi-train-car" });
        this.stepIndexes.tourStep = stepCounter;
        stepCounter++;
      }

      if (this.flyCheck) {
        this.steps.push({ icon: "mdi mdi-airplane" });
        this.stepIndexes.flyStep = stepCounter;
      }

      this.steps.push({ icon: "mdi mdi-checkbox-marked-circle-outline" });
    },
    nextStep() {
      if (
        this.currentStep === 1 &&
        !this.hotelCheck &&
        !this.tourCheck &&
        !this.flyCheck
      ) {
        alert("Lütfen bir rezervasyon türü seçin.");
        return;
      }
      if (this.currentStep < this.steps.length) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    submitReservation() {
      alert("Rezervasyon tamamlandı!");
    },
    getAllAirports() {
     this.getData("/airport")
        .then((response) => {
          this.editProps.airports = response.data;
          console.log("airports", this.editProps.airports);
        })
        .catch((error) => {
          console.error(error);
        }); 
    },
    getAllCustomer() {
      this.getData("/customer/getAll")
        .then((response) => {
          this.editProps.customers = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllHotel() {
      this.getData("/hotel/getAll")
        .then((response) => {
          this.editProps.hotels = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllCity() {
      this.getData("/city/getAll")
        .then((response) => {
          this.editTourProps.cities = response.data;
          console.log(this.editTourProps);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllVehicle() {
      this.getData("/vehicle/getAll")
        .then((response) => {
          this.editTourProps.vehicles = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllDrivers() {
      const api_url = process.env.VUE_APP_BASEURL + "/user/getAll?authority=5";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        })
        .then((response) => {
          this.editTourProps.drivers = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getToken() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user ? user.token : "";
    },
    getAllCurrency() {
      this.getData("/exchange/getAll")
        .then((response) => {
          this.editProps.currencies = response.data;
          this.editTourProps.currencies = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllAgency() {
      this.getData("/agency/getAll")
        .then((response) => {
          this.editTourProps.agencies = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData(endpoint) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_BASEURL + endpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    onComplete() {
      if (this.isLoading == true) return;
      if (this.isSaved == true) return;
      this.isLoading = true;
      const api_url =
        process.env.VUE_APP_BASEURL + "/reservation/" + this.$route.params.id;

      const hotelType = this.hotelCheck == true ? 1 : 0;
      const tourType = this.tourCheck == true ? 2 : 0;
      const flyType = this.flyCheck == true ? 3 : 0;

      const types = hotelType + "," + tourType + "," + flyType;

      const allReservations = this.reservations.map((reservation) => {
        return {
          id: reservation.id,
          adult_count: reservation.adult_count,
          baby_count: reservation.baby_count,
          board: reservation.board.value,
          buy_currency: reservation.buy_currency.id,
          sell_currency: reservation.sell_currency.id,
          buy_price: reservation.buy_price,
          sell_price: reservation.sell_price,
          cancelability: reservation.cancelability.value,
          children_count: reservation.children_count,
          confirme_number: reservation.confirme_number,
          first_date: reservation.first_date,
          last_date: reservation.last_date,
          hotel: reservation.hotel.id,
          room: reservation.room.id,
          room_count: reservation.room_count,
        };
      });

      const allTourRoutes = this.tourRoutes.map((tourRoute) => {
        return {
          id: tourRoute.id,
          order: tourRoute.order,
          date: tourRoute.date,
          city: tourRoute.city.id,
          type: tourRoute.tour_type.value,
          plate: tourRoute.vehicle.id,
          driver: tourRoute.driver.id,
          sub_agency: tourRoute.sub_agency,
          sub_agency_id: tourRoute.sub_agency_id,
          //
          payment_type: tourRoute.payment_type.value,
          buy_price: tourRoute.buy_price,
          buy_currency: tourRoute.buy_currency.id,
          sell_price: tourRoute.sell_price,
          sell_currency: tourRoute.sell_currency.id,
          //
        };
      });
      const allFlyRoutes = this.flyRoutes.map((flyRoute) => {
        return {
          id: flyRoute.id,
          airline_name: flyRoute.airline_name,
          flight_number: flyRoute.flight_number,
          flight_pnr: flyRoute.flight_pnr,
          flight_from: flyRoute.flight_from.id,
          flight_from_date: flyRoute.flight_from_date,
          flight_to: flyRoute.flight_to.id,
          flight_to_date: flyRoute.flight_to_date,
          flight_ticket_number: flyRoute.flight_ticket_number,
          flight_ticket_class: flyRoute.flight_ticket_class.value,
          flight_class: flyRoute.flight_class,
          flight_baggage: flyRoute.flight_baggage,
          reservation_number: flyRoute.reservation_number,
          packet: flyRoute.packet,
          preferences: flyRoute.preferences,
          customer: flyRoute.customer.id,
          buy_price: flyRoute.buy_price,
          sell_price: flyRoute.sell_price,
          buy_currency: flyRoute.buy_currency.id,
          sell_currency: flyRoute.sell_currency.id,
        };
      });

      const requestData = {
        customer_id: this.selectedValues.customer.id,
        agency_id: this.selectedValues.agency.id,
        reservation_type: "{" + types + "}",
        status: this.selectedValues.status.status,
        note: this.selectedValues.note,
        reservations: allReservations,
        tourRoutes: allTourRoutes,
        flyRoutes: allFlyRoutes,
        extras: JSON.stringify(this.extras),
      };

      axios
        .put(api_url, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.isSaved = true;
          //this.$router.push("/reservation");
        })
        .catch((error) => {
          this.isSaved = false;
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleReservationLoaded(reservations) {
      this.reservations = reservations;
    },
    handleTourLoaded(tourRoutes) {
      this.tourRoutes = tourRoutes;
    },
    handleFlyLoaded(flyRoutes) {
      this.flyRoutes = flyRoutes;
    },
    updateHotelCheck(value) {
      this.hotelCheck = value;
    },
    updateTourCheck(value) {
      this.tourCheck = value;
    },
    updateFlyCheck(value) {
      this.flyCheck = value;
    },
    updateExtras(value) {
      this.extras = value;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-12">
        <div class="float-right mb-3">
          <b-button
            v-if="flyRoutes.length > 0"
            variant="primary"
            class="btn"
            @click="$router.push(`/reservation/ticket/` + $route.params.id)"
          >
            Bilet
          </b-button>
          <b-dropdown
            v-if="tourRoutes.length > 0"
            id="dropdown-right"
            right
            text="Tur Rotası"
            variant="primary"
            class="btn btn-rounded"
          >
            <b-dropdown-item
              @click="$router.push(`/reservation/tourlist/` + $route.params.id)"
              >Fiyatsız Rota Listesi</b-dropdown-item
            >
            <b-dropdown-item
              @click="
                $router.push(`/reservation/tourlist/n/` + $route.params.id)
              "
              >Fiyatlı Rota Listesi</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown
            id="dropdown-right"
            right
            text="Voucher"
            variant="primary"
            class="btn btn-rounded"
          >
            <b-dropdown-item
              @click="$router.push(`/reservation/voucher/` + $route.params.id)"
              >Fiyatlı Voucher</b-dropdown-item
            >
            <b-dropdown-item
              @click="
                $router.push(`/reservation/voucher/n/` + $route.params.id)
              "
              >Fiyatsız Voucher</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- <form-wizard
              color="#252b3b"
              nextButtonText="İleri"
              backButtonText="Geri"
              finishButtonText="Bitir"
              @on-complete="onComplete"
            >
              <tab-content icon="mdi mdi-account-circle">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row">
                      <label class="col-md-2 col-form-label"
                        >Rezervasyon Türü</label
                      >
                      <div class="col-md-10">
                        <div class="row">
                          <div class="col-4">
                            <div class="form-check mb-3">
                              <input
                                class="form-check-input"
                                v-model="hotelCheck"
                                type="checkbox"
                                id="hotelReservationCheck"
                              />
                              <label
                                class="form-check-label"
                                for="hotelReservationCheck"
                                >Otel Rezervasyonu</label
                              >
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-check mb-3">
                              <input
                                class="form-check-input"
                                v-model="tourCheck"
                                type="checkbox"
                                id="tourReservationCheck"
                              />
                              <label
                                class="form-check-label"
                                for="tourReservationCheck"
                                >Tur Rezervasyonu</label
                              >
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-check mb-3">
                              <input
                                class="form-check-input"
                                v-model="flyCheck"
                                type="checkbox"
                                id="flyReservationCheck"
                              />
                              <label
                                class="form-check-label"
                                for="flyReservationCheck"
                                >Uçak Rezervasyonu</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <EditReservation
                      :selectedValues.sync="selectedValues"
                      :hotelCheck="hotelCheck"
                      :tourCheck="tourCheck"
                      :flyCheck="flyCheck"
                      :extras="extras"
                      @update:hotelCheck="updateHotelCheck"
                      @update:tourCheck="updateTourCheck"
                      @update:flyCheck="updateFlyCheck"
                      @update:extras="updateExtras"
                      @reservationLoaded="handleReservationLoaded"
                      @tourLoaded="handleTourLoaded"
                      @flyLoaded="handleFlyLoaded"
                    />
                  </div>
                </div>
              </tab-content>
              <tab-content v-if="hotelCheck" icon="mdi mdi-home-city-outline">
                <HotelComponent
                  :reservations="reservations"
                  :editProps="editProps"
                />
              </tab-content>
              <tab-content v-if="tourCheck" icon="mdi mdi-train-car">
                <TourComponent
                  :reservations="reservations"
                  :extras.sync="extras"
                  :tourRoutes="tourRoutes"
                  :editTourProps="editTourProps"
                />
              </tab-content>
              <tab-content v-if="flyCheck" icon="mdi mdi-airplane">
                <FlyComponent
                  :flyRoutes="flyRoutes"
                  :editProps="editProps"
                  :reservations="reservations"
                />
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h3 class="mt-0" v-if="isSaved">
                        Rezervasyon Başarıyla Güncellendi!
                      </h3>
                      <div class="mt-0" v-if="isLoading">
                        <Icon
                          icon="lucide:loader"
                          :style="{ fontSize: '48px' }"
                        />
                        <h3 class="mt-0">Kaydediliyor...</h3>
                        <p class="w-75 mb-2 mx-auto">
                          Güncellediğiniz rezervasyon kaydediliyor, lütfen
                          bekleyin.
                        </p>
                      </div>
                      <div class="mt-0" v-else-if="isSaved">
                        <Icon
                          icon="lucide:check-check"
                          :style="{ fontSize: '48px' }"
                        />
                        <h3 class="mt-0">Kaydedildi!</h3>
                        <p class="w-75 mb-2 mx-auto">
                          Rezervasyon başarıyla güncellendi, rezervasyonlar
                          sayfasına yönlendiriliyorsunuz.
                        </p>
                      </div>
                      <div class="mt-0" v-else>
                        <Icon
                          icon="lucide:save"
                          :style="{ fontSize: '48px' }"
                        />
                        <h3 class="mt-0">Rezervasyonu Güncelle</h3>
                        <p class="w-75 mb-2 mx-auto">
                          Aşağıdaki bilgilere sahip rezervasyonu kaydetmek için
                          bitir butonuna <u><b>bir defa</b></u> tıklayın.
                        </p>
                      </div>
                    </div>
                  </div>
                  end col 
                </div>
                end row
              </tab-content>
            </form-wizard> -->
            <div>
              <!-- Adım İlerlemesi -->
              <div class="progress-bar">
                <div
                  v-for="(step, index) in steps"
                  :key="index"
                  class="step"
                  :class="{ active: currentStep === index + 1 }"
                >
                  <div class="vertical-line"></div>
                  <span class="icon">
                    <i :class="step.icon"></i>
                  </span>
                  <span class="label">{{ step.label }}</span>
                </div>
              </div>

              <!-- Adım 1: Rezervasyon Türü Seçimi -->
              <div class="rezerv" v-if="currentStep === 1">
                <div class="reservation-type">
                  <label>Rezervasyon Türü</label>
                  <label
                    ><input type="checkbox" v-model="hotelCheck" /> Otel
                    Rezervasyonu</label
                  >
                  <label
                    ><input type="checkbox" v-model="tourCheck" /> Tur
                    Rezervasyonu</label
                  >
                  <label
                    ><input type="checkbox" v-model="flyCheck" /> Uçak
                    Rezervasyonu</label
                  >
                </div>
                <EditReservation
                      :selectedValues.sync="selectedValues"
                      :hotelCheck="hotelCheck"
                      :tourCheck="tourCheck"
                      :flyCheck="flyCheck"
                      :extras="extras"
                      @update:hotelCheck="updateHotelCheck"
                      @update:tourCheck="updateTourCheck"
                      @update:flyCheck="updateFlyCheck"
                      @update:extras="updateExtras"
                      @reservationLoaded="handleReservationLoaded"
                      @tourLoaded="handleTourLoaded"
                      @flyLoaded="handleFlyLoaded"
                    />
                <div class="button-group-new">
                  <button class="btn btn-primary" @click="nextStep">İleri</button>
                </div>
              </div>

              <!-- Adım 2: Rezervasyon Bilgileri -->
              <div v-if="currentStep > 1 && currentStep <= steps.length">
                <div
                  v-if="currentStep === stepIndexes.hotelStep"
                  v-show="hotelCheck"
                >
                <HotelComponent
                  :reservations="reservations"
                  :editProps="editProps"
                />
                </div>

                <div
                  v-if="currentStep === stepIndexes.tourStep"
                  v-show="tourCheck"
                >
                <TourComponent
                  :reservations="reservations"
                  :extras.sync="extras"
                  :tourRoutes="tourRoutes"
                  :editTourProps="editTourProps"
                />
                </div>

                <div
                  v-if="currentStep === stepIndexes.flyStep"
                  v-show="flyCheck"
                >
                <FlyComponent
                  :flyRoutes="flyRoutes"
                  :editProps="editProps"
                  :reservations="reservations"
                />
                </div>

                <div class="button-group-new mb-5">
                  <button class="btn btn-secondary" @click="prevStep">Geri</button>
                  <button class="btn btn-primary" @click="nextStep" v-if="currentStep < steps.length">İleri</button>
                  <button class="btn btn-success" @click="onComplete" v-if="currentStep === steps.length">Bitir</button>
                </div>
              </div>

              <!-- Adım 3: Onaylama -->
              <div v-if="currentStep === steps.length">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h3 class="mt-0" v-if="isSaved">
                        Rezervasyon Başarıyla Güncellendi!
                      </h3>
                      <div class="mt-0" v-if="isLoading">
                        <Icon
                          icon="lucide:loader"
                          :style="{ fontSize: '48px' }"
                        />
                        <h3 class="mt-0">Kaydediliyor...</h3>
                        <p class="w-75 mb-2 mx-auto">
                          Güncellediğiniz rezervasyon kaydediliyor, lütfen
                          bekleyin.
                        </p>
                      </div>
                      <div class="mt-0" v-else-if="isSaved">
                        <Icon
                          icon="lucide:check-check"
                          :style="{ fontSize: '48px' }"
                        />
                        <h3 class="mt-0">Kaydedildi!</h3>
                        <p class="w-75 mb-2 mx-auto">
                          Rezervasyon başarıyla güncellendi, rezervasyonlar
                          sayfasına yönlendiriliyorsunuz.
                        </p>
                      </div>
                      <div class="mt-0" v-else>
                        <Icon
                          icon="lucide:save"
                          :style="{ fontSize: '48px' }"
                        />
                        <h3 class="mt-0">Rezervasyonu Güncelle</h3>
                        <p class="w-75 mb-2 mx-auto">
                          Aşağıdaki bilgilere sahip rezervasyonu kaydetmek için
                          bitir butonuna <u><b>bir defa</b></u> tıklayın.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <aside v-if="reservations.length > 0">
      <HotelTable :reservations="reservations" :editProps="editProps" />
    </aside>
    <aside v-if="tourRoutes.length > 0">
      <TourTable :tourRoutes="tourRoutes" :editTourProps="editTourProps" />
    </aside>
    <aside v-if="flyRoutes.length > 0">
      <FlyTable :flyRoutes="flyRoutes" :editProps="editProps" />
    </aside>
  </Layout>
</template>

<style>
.progress-bar {
  width: 100% !important;
  height: 200px !important;
  display: inline;
  justify-content: space-around;
  margin-bottom: 60px !important;
  background-color: transparent !important;
  padding: 10px;
  line-height: 10;
}
.vertical-line {
  display: flex;
  background-color: #252b3b;
  width: 150px;
  height: 2px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.step {
  width: 20%;
  display: inline-flex;
  justify-content: space-between;
}
.step:first-child {
  position: relative;
  margin-right: 0;
}
.step:last-child {
  position: relative;
  margin-left: 0 !important;
}
.icon {
  font-size: 24px;
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.icon {
  color: #252b3b !important;
}
.label {
  font-size: 12px;
  color: #666;
}
.active .label {
  color: #252b3b;
}
.reservation-type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 10px;
}
.button-group-new {
  text-align: right;
  margin-top: 20px;
}
.button-group-new .btn {
  margin-left: 10px;
  padding: 10px 50px;
}
</style>
