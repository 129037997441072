<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import format from "date-fns/format";
import { statusEnum } from "@/components/enums.js";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  props: ["selectedValues", "hotelCheck", "tourCheck", "flyCheck"],
  components: {
    Multiselect,
  },
  data() {
    return {
      statusEnum,
      customer: "",
      customers: [],
      agency: "",
      agencies: [],
      values: {
        customer: "",
        agency: "",
        status: "",
        statusName: "",
        note: "",
        reservation_type: "",
        reservation_typeName: "",
      },
      localHotelCheck: this.hotelCheck,
      localTourCheck: this.tourCheck,
      localFlyCheck: this.flyCheck,
    };
  },
  computed: {
    computedHotelCheck() {
      return this.localHotelCheck;
    },
    computedTourCheck() {
      return this.localTourCheck;
    },
    computedFlyCheck() {
      return this.localFlyCheck;
    },
  },
  watch: {
    computedAllCheck: "checkReservationType",
  },

  created() {
    this.getAllReservationById();
    this.getAllCustomer();
    this.getAllAgency();
  },

  methods: {
    updateSelectedValues() {
      this.$emit("update:selectedValues", {
        ...this.selectedValues,
        customer: this.values.customer,
        agency: this.values.agency,
        status: this.values.status,
        note: this.values.note,
      });
    },
    checkReservationType() {
      const cleanedType = this.values.reservation_type.type.replace(
        /[{} ]/g,
        ""
      );

      const types = cleanedType.split(",").map((type) => parseInt(type));

      this.localHotelCheck = types.includes(1);
      this.localTourCheck = types.includes(2);
      this.localFlyCheck = types.includes(3);

      this.$emit("update:hotelCheck", this.localHotelCheck);
      this.$emit("update:tourCheck", this.localTourCheck);
      this.$emit("update:flyCheck", this.localFlyCheck);
    },

    getAllCustomer() {
      const api_url = process.env.VUE_APP_BASEURL + "/customer/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.customers = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllAgency() {
      const api_url = process.env.VUE_APP_BASEURL + "/agency/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.agencies = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllReservationById() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/reservation/${itemId}`;
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = response.data?.data;
          //const reservationData = response.data?.data;
          const toursData = response.data?.data;
          this.$emit("update:extras", JSON.parse(response.data?.data.extras));
          this.$emit("reservationLoaded", response.data?.data.reservations);
          this.$emit("tourLoaded", response.data?.data.tourRoutes);
          this.$emit("flyLoaded", response.data?.data.flyRoutes);
          console.log(toursData);
          this.checkReservationType();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateFormData() {
      this.values.customer = this.updateSelectedValues.customer;
    },
    formatDate(date) {
      return format(date, "dd/MM/yyyy");
    },
    formatDateData(date) {
      return format(date, "yyyy-MM-dd");
    },
  },
};
</script>
<template>
  <div>
    <div class="form-group row" v-if="hotelCheck">
      <label class="col-md-2 col-form-label">Müşteri</label>
      <div class="col-md-10">
        <multiselect
          v-model="values.customer"
          :options="customers"
          label="customer_name"
          @input="updateSelectedValues"
        ></multiselect>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label">Acenta</label>
      <div class="col-md-10">
        <multiselect
          v-model="values.agency"
          :options="agencies"
          label="company_name"
          @input="updateSelectedValues"
        ></multiselect>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label">Rezervasyon Durumu</label>
      <div class="col-md-10">
        <b-form-select
          v-model="values.status.status"
          :options="statusEnum"
          class="form-control"
          @input="updateSelectedValues"
        ></b-form-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label">Rezervasyon Notu</label>
      <div class="col-md-10">
        <input
          v-model="values.note"
          type="text"
          class="form-control"
          id="note"
          @input="updateSelectedValues"
        />
      </div>
    </div>
  </div>
</template>
